<template>
  <validation-observer ref="form">
    <!-- <pre>{{ form }}</pre> -->
    <div class="mb-1 text-right">
      <small>* จำเป็นต้องระบุ</small>
    </div>
    <b-form>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group>
            <label :for="labelValidateFieldNameEn">{{ cFormLabelNameEN }}</label>
            <validation-provider
              #default="{ errors }"
              :name="labelValidateFieldNameEn"
              rules="required"
            >
              <b-form-input
                v-model="form.name_en"
                :state="errors.length > 0 ? false : null"
                :name="labelValidateFieldNameEn"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group>
            <label :for="labelValidateFieldNameTh">{{ cFormLabelNameTH }}</label>
            <validation-provider
              #default="{ errors }"
              :name="labelValidateFieldNameTh"
              rules="required"
            >
              <b-form-input
                v-model="form.name_th"
                :state="errors.length > 0 ? false : null"
                :name="labelValidateFieldNameTh"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-sm-end flex-column flex-sm-row">
        <button-loading-widget
          :text="cButtonAddOrEdit"
          variant="primary"
          class="mb-50 mb-sm-0 mr-0 mr-sm-50"
          :loading="loading"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="onClickAddOrEdit"
        />
        <button-loading-widget
          text="ล้างข้อมูล"
          variant="flat-secondary"
          :disabled="loading"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="clearForm"
        />
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    fields: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    labelValidateFieldNameTh: {
      type: String,
      default: 'name_th',
    },
    labelValidateFieldNameEn: {
      type: String,
      default: 'name_en',
    },
  },
  data() {
    return {
      form: {
        name_th: '',
        name_en: '',
      },
      typeAction: 'add',
      loading: false,
    }
  },
  computed: {
    cButtonAddOrEdit() {
      if (this.typeAction === 'edit') return `แก้ไข${this.title}`

      return `เพิ่ม${this.title}`
    },
    cFormLabelNameTH() {
      return `*${this.title} ภาษาไทย`
    },
    cFormLabelNameEN() {
      return `*${this.title} ภาษาอังกฤษ`
    },
  },
  methods: {
    async validateForm() {
      const v = await this.$refs.form.validate()

      if (!v) return { data: null, clearForm: this.clearForm }

      return { data: this.form, clearForm: this.clearForm }
    },
    clearForm() {
      this.form = {
        name_th: '',
        name_en: '',
      }
      this.typeAction = 'add'
      this.$refs.form.reset()
    },
    updateForm(data) {
      //   console.log('updateForm', data)
      this.form = { ...data }
      this.typeAction = 'edit'
    },
    async onClickAddOrEdit() {
      const { data, clearForm } = await this.validateForm()
      if (!data) return
      this.loading = true
      if (this.typeAction === 'add') {
        await this.add()
      } else {
        await this.edit()
      }

      this.loading = false
      clearForm()
    },
    async add() {
      const requestData = { ...this.form, table: this.table, fields: this.fields }
      const resp = await this.$http.post('/api/miscellaneous-data', requestData).catch(() => null)
      // console.log('add', resp)
      if (!resp) return

      if (resp.code === 405) {
        this.gDisplayToast('ไม่สามารถเพิ่มข้อมูลได้', 'เนื่องจากข้อมูลซ้ำกันในระบบ', 'danger')
        return
      }

      this.$emit('onAddOrEditSuccess', { data: resp, typeAction: this.typeAction })
    },
    async edit() {
      const requestData = { ...this.form, table: this.table, fields: this.fields }
      const resp = await this.$http.put(`/api/miscellaneous-data/${this.form?.id}`, requestData).catch(() => null)
      // console.log('edit', resp)
      if (!resp) return

      if (resp.code === 405) {
        this.gDisplayToast('ไม่สามารถแก้ไขข้อมูลได้', 'เนื่องจากข้อมูลซ้ำกันในระบบ', 'danger')
        return
      }

      this.$emit('onAddOrEditSuccess', { data: resp, typeAction: this.typeAction })
    },
  },
}
</script>

<style lang="scss" scoped></style>
