<template>
  <div>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <!-- search -->
      <div class="d-flex flex-column flex-sm-row align-items-sm-center m-1">
        <slot name="headerTitle">
          <div class="d-flex align-items-center mb-50 mb-sm-0">
            <h4
              v-if="title"
              class="mb-0"
            >
              {{ title }}
            </h4>
            <div
              v-if="showAddButton"
              :class="title ? 'ml-50' : 'ml-0'"
            >
              <button-loading-widget
                variant="primary"
                icon="PlusIcon"
                size="sm"
                :disabled="loading"
                @click="$emit('clickAdd')"
              />
            </div>
          </div>
        </slot>

        <b-form-input
          v-if="showSearch"
          id="searchInputTable"
          v-model="search"
          debounce="500"
          class="w-auto ml-sm-auto"
          type="search"
          size="sm"
          placeholder="ค้นหา..."
          :disabled="loading"
        />
      </div>

      <!-- table -->
      <b-table
        :items="rows"
        :fields="cColumns"
        :busy="loading"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="search"
        hover
        responsive
        show-empty
        empty-text="ไม่พบข้อมูล"
        empty-filtered-text="ไม่พบข้อมูลที่ค้นหา"
        @filtered="onFiltered"
      >
        <!-- head columns -->
        <template #head()="data">
          <div :style="{ minWidth: 'max-content' }">
            <h5 class="font-weight-bolder">{{ data.label.toUpperCase() }}</h5>
          </div>
        </template>

        <!-- body rows -->
        <template #cell(index)="data">
          <span class="text-center">
            {{ calculatePageIndex(data.index) }}
          </span>
        </template>

        <!-- body action -->
        <template #cell(actions)="data">
          <div class="text-nowrap text-center user-select-none">
            <feather-icon
              v-if="viewAble"
              icon="EyeIcon"
              class="cursor-pointer mx-50"
              size="16"
              @click="$emit('clickView', data)"
            />
            <feather-icon
              v-if="editAble"
              icon="EditIcon"
              class="cursor-pointer mx-50"
              size="16"
              @click="$emit('clickEdit', data)"
            />
            <feather-icon
              v-if="deleteAble"
              icon="TrashIcon"
              class="cursor-pointer mx-50 text-danger"
              size="16"
              @click="$emit('clickDelete', data)"
            />
          </div>
        </template>

        <template #cell()="data">
          <slot
            :name="data.field.key"
            :data="data"
          >
            <span>{{ data.value }}</span>
          </slot>
        </template>

        <!-- loading -->
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner variant="primary" />
          </div>
        </template>

        <!-- empty -->
        <template #empty="scope">
          <h5 class="text-muted text-center my-5">{{ scope.emptyText }}</h5>
        </template>
        <template #emptyfiltered="scope">
          <h5 class="text-muted text-center my-5">{{ scope.emptyFilteredText }}</h5>
        </template>
      </b-table>

      <!-- paging -->
      <b-row class="px-1 pb-1">
        <b-col
          sm="12"
          md="auto"
        >
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-auto"
            />
            <label class="d-inline-block text-sm-left ml-50">of {{ totalRows }}</label>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="auto"
          class="ml-auto mt-1 mt-sm-0"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [{ key: 'index', label: '#' }],
    },
    pageOptions: {
      type: Array,
      default: () => [10, 25, 50, 75, 100],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    viewAble: {
      type: Boolean,
      default: false,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      search: '',
      totalRows: 0,
    }
  },
  computed: {
    cColumns() {
      let newColumns = [...this.columns]

      if (this.editAble || this.viewAble || this.deleteAble) {
        newColumns = [
          ...newColumns,
          { key: 'actions', label: 'เลือก', thStyle: { width: '10%' }, thClass: 'text-center' },
        ]
      }

      if (this.showIndex) {
        newColumns = [{ key: 'index', label: '#', thStyle: { width: '5%' }, thClass: 'text-center' }, ...newColumns]
      }

      return newColumns
    },
  },
  watch: {
    rows(newVal) {
      this.search = ''
      this.totalRows = newVal.length
    },
  },
  created() {
    this.totalRows = this.rows.length
  },
  methods: {
    calculatePageIndex(index) {
      if (this.currentPage === 1) return index + 1
      return this.perPage * (this.currentPage - 1) + (index + 1)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped></style>
