<template>
  <DatePicker
    v-model="inputVal"
    :lang="currentLang"
    :name="name"
    :placeholder="placeholder"
    :formatter="formatBEDate"
    class="ct-form-control"
  >
    <template
      slot="input"
      slot-scope="props"
    >
      <b-form-input
        v-model="props.props.value"
        :placeholder="props.props.placeholder"
        :type="props.props.type"
        :autocomplete="props.props.autocomplete"
        :readonly="props.props.readonly"
        :disabled="props.props.readonly"
        :name="props.props.name"
        :class="[error ? 'error' : '']"
        @keydown="props.events.keydown"
        @focus="props.events.focus"
        @blur="props.events.blur"
        @input="props.events.input"
        @change="props.events.change"
      />
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import dayjs from 'dayjs'

export default {
  components: { DatePicker },
  props: {
    value: {
      type: Date,
      default() {
        return null
      },
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatBEDate: {
        stringify: date => {
          // console.log('date', date)

          if (!date) return null

          return dayjs(date).format(this.$i18n.locale === 'th' ? 'DD/MM/BBBB' : 'DD/M/YYYY')
        },
        parse: value => {
          // console.log('value', value)

          if (!value) return null

          // let valueFormatCE = value

          const dateFormatArrBE = `${value}`.split('/')
          const yearCE = this.$i18n.locale === 'th' ? Number(dateFormatArrBE[2]) - 543 : Number(dateFormatArrBE[2])

          if (yearCE <= 1) {
            dateFormatArrBE[2] = 1
          } else {
            dateFormatArrBE[2] = yearCE
          }

          const valueFormatCE = `${dateFormatArrBE[1]}/${dateFormatArrBE[0]}/${dateFormatArrBE[2]}`

          return dayjs(valueFormatCE, 'DD/MM/YYYY').toDate()
        },
      },
    }
  },
  computed: {
    currentLang() {
      const { locale } = this.$i18n

      if (locale === 'th') return 'th'

      return 'en'
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.ct-form-control {
  width: 100%;
}
.form-control.error {
  border-color: red;
}
</style>
