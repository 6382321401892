import mock from '@/@fake-db/mock'

const data = [
  {
    id: 'c968187f-df30-4920-be27-2b2d8cbcc3ee',
    branch_data_id: '6305c009-6719-4c20-b5dd-3b3912467488',
    name_th: 'ชมพู-ดำ',
    name_en: 'P-B',
    table: 'stock_data',
    fields: 'code_color_id',
    menu_order: 3,
  },
  {
    id: 'e8a75624-7c14-452a-becf-405ddc1f8c29',
    branch_data_id: '6305c009-6719-4c20-b5dd-3b3912467488',
    name_th: 'ชมพู-ขาว',
    name_en: 'P-W',
    table: 'stock_data',
    fields: 'code_color_id',
    menu_order: 4,
  },
]

mock.onGet('/mock/api/miscellaneous-data/stock_data/code_color_id').reply(200, { status: true, code: 200, data })

mock.onPost('/mock/api/miscellaneous-data').reply(request => {
  const requestData = JSON.parse(request.data)

  return [200, { status: true, code: 400, data: 'success' }]
})

mock.onDelete(/\/mock\/api\/miscellaneous-data\/[^]+/).reply(config => {
  const id = config.url.substring(config.url.lastIndexOf('/') + 1)
  const colorIndex = data.findIndex(v => v.id === id)
  const color = data[colorIndex]
  if (!color) return [200, { status: false, code: 405, data: 'not found color' }]

  data.splice(colorIndex, 1)

  return [200, { status: true, code: 200, data: 'success' }]
})
