import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  max_value as rule_max_value,
  min_value as rule_min_value,
} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import th from 'vee-validate/dist/locale/th.json'

import { methodMixin } from '../../../global-mixin'
// eslint-disable-next-line object-curly-newline
import { validatorPositive, validatorUrlValidator, validatorPassword, validatorCreditCard } from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const minValue = extend('min_value', rule_min_value)
export const maxValue = extend('max_value', rule_max_value)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

// Install English and Arabic localizations.
localize({
  en: {
    messages: {
      ...en.messages,
      min_value: (_field_, { min: _min_ }) => `The field must be ${methodMixin.gFormatNumberToCurrency(_min_)} or more`,
      max_value: (_field_, { max: _max_ }) => `The field must be ${methodMixin.gFormatNumberToCurrency(_max_)} or less`,
      required: () => 'The field is required',
    },
    names: {
      email: 'Email',
      password: 'Password',
      dealer_code: 'Dealer Code',
      tex_invoice_no: 'Tax Invoice No.',
      delivery_date: 'Delivery Date',
      model: 'Model',
      colors: 'Colors',
      frame_no: 'Frame No.',
      engine_no: 'Engin No.',
      invoice_amount_include_vat: 'Invoice Amount Include Vat.',
      shop_code: 'Shop Code',
      customer_branch: 'Customer Branch',
      customer_name: 'Customer Name',
      tax_invoice_number: 'Tax Invoice Number',
      date_of_issue_of_tax_invoice: 'Date Of Issue Of Tax Invoice',
      order_type: 'Order Type',
      parts_number: 'Part Number',
      parts_name_th: 'Part Name TH',
      parts_name_en: 'Part Name EN',
      retail_price: 'Retail Price',
      number_of_exits: 'Number',
      net_price_per_unit: 'Net Price Per Unit',
      name_color_th: 'Name Color TH',
      name_color_en: 'Name Color EN',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
  th: {
    messages: { ...th.messages, required: () => 'จำเป็นต้องระบุ' },
    names: {
      email: 'อีเมล',
      password: 'รหัสผ่าน',
      dealer_code: 'รหัสตัวแทนจำหน่าย',
      tex_invoice_no: 'รหัสอ้างอิงภาษี',
      delivery_date: 'วันที่รับ',
      model: 'โมเดล',
      colors: 'สี',
      frame_no: 'หมายเลขเฟรม',
      engine_no: 'หมายเลขเครื่องยนต์',
      invoice_amount_include_vat: 'ราคารวมภาษี',
      shop_code: 'รหัสร้าน',
      customer_branch: 'สาขาลูกค้า',
      customer_name: 'ชื่อลูกค้า',
      tax_invoice_number: 'เลขที่ใบกำกับภาษี',
      date_of_issue_of_tax_invoice: 'วันที่ออกใบกำกับภาษี',
      order_type: 'ประเภทการสั่งซื้อ',
      parts_number: 'หมายเลขอะไหล่',
      parts_name_th: 'ชื่ออะไหล่ ภาษาไทย',
      parts_name_en: 'ชื่ออะไหล่ ภาษาอังกฤษ',
      retail_price: 'ราคาขายปลีกแนะนำ',
      number_of_exits: 'จำนวน',
      net_price_per_unit: 'ราคาสุทธิต่อหน่วย',
      name_color_th: 'ชื่อสีภาษาไทย',
      name_color_en: 'ชื่อสีภาษาอังกฤษ',
    },
  },
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
