import Vue from 'vue'

// axios
import axios from 'axios'

import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_BASEURL,
  timeout: 20000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const axiosShowAlert = (title = '', text = '', variant = 'danger', icon = 'BellIcon') => {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant,
    },
  })
}

axiosIns.interceptors.response.use(
  resp => {
    // console.log(`[Axios][${resp.config.method.toLocaleUpperCase()}]`, resp)

    const {
      data,
      config: { method, useAlert = {} },
    } = resp

    let cTitle = ''
    const cUserAlert = {
      title: null,
      text: null,
      variant: null,
      icon: null,
      successEnable: true,
      errorEnable: true,
      ...useAlert,
    }

    switch (data.code) {
      case 422:
        if (cUserAlert.errorEnable) {
          if (typeof data.data === 'object') {
            Object.keys(data.data).forEach(key => {
              axiosShowAlert(data.data[key][0])
            })
          } else {
            axiosShowAlert('ข้อมูลไม่ถูกต้อง', 'โปรตรวจสอบข้อมูลอีกครั้ง')
          }
        }
        return Promise.resolve(data)
      case 403:
        if (cUserAlert.errorEnable) {
          axiosShowAlert('[ERROR: 433]', JSON.stringify(data.data))
        }
        return Promise.resolve(data)
      case 200:
        if (method === 'post') {
          cTitle = cUserAlert.title ?? 'เพิ่มข้อมูลสำเร็จ'
        }

        if (method === 'put') {
          cTitle = cUserAlert.title ?? 'แก้ไขข้อมูลสำเร็จ'
        }

        if (method === 'delete') {
          cTitle = cUserAlert.title ?? 'ลบข้อมูลสำเร็จ'
        }

        if (cUserAlert.successEnable && method !== 'get') {
          axiosShowAlert(cTitle, cUserAlert.text, cUserAlert.variant ?? 'success', cUserAlert.icon ?? 'CheckIcon')
        }
        return Promise.resolve(data)
      default:
        return Promise.resolve(data)
    }
  },
  error => {
    // console.log('[Axios]::error => ', { error })

    if (error.code === 'ECONNABORTED') {
      axiosShowAlert('Timeout Error', 'โปรดติดต่อผู้ดูแลระบบ', 'danger', 'BellIcon')
    }

    if (error.message === 'Network Error') {
      axiosShowAlert('Network Error', 'โปรดติดต่อผู้ดูแลระบบ', 'danger', 'BellIcon')
    }

    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/login'
    }

    if (error.response.status === 500) {
      axiosShowAlert('[500] ไม่สามารถเรียกเซิฟเวอร์ได้', 'โปรดติดต่อผู้ดูแลระบบ', 'danger', 'CloudOffIcon')
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
