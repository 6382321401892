<template>
  <div>
    <b-card>
      <div class="ct-container">
        <feather-icon
          icon="UploadCloudIcon"
          size="50"
        />
        <br />
        <h4 class="mb-50">คลิกเพื่ออัปโหลดไฟล์</h4>
        <small class="text-muted mb-50">หรือ</small>
        <small>ลากไฟล์มาที่นี้เพื่ออัปโหลด</small>
        <small v-if="accept">ต้องการไฟล์นามสกุล {{ accept }} เท่านั้น</small>
        <input
          id="fileUploadInput"
          ref="fileUploadInput"
          type="file"
          name="fileUploadInput"
          class="file-upload"
          :accept="accept"
          :disabled="uploading"
          @change="onChange"
        />
      </div>
      <b-progress
        v-if="uploading"
        animated
        :value="progressUpload"
        variant="primary"
        class="'progress-bar-primary mt-1"
      />
    </b-card>
  </div>
</template>

<script>
import { read, utils } from 'xlsx'

export default {
  props: {},
  data() {
    return {
      progressUpload: 0,
      uploading: false,
      timeOut: null,
      accept: '.xls,.xlsx,.csv',
    }
  },
  methods: {
    onChange(ev) {
      const file = ev.target.files[0]

      if (!file) return

      const arrFilename = `${file.name}`.split('.')

      const fileType = `.${arrFilename[arrFilename.length - 1]}`
      const allowTypeFile = this.accept.split(',')

      const validate = allowTypeFile.includes(fileType)

      if (!validate) {
        this.$refs.fileUploadInput.value = null
        // console.log('validate fail')
        this.gDisplayToast('นามสกุลไฟล์ไม่ถูกต้อง', `นามสกุลไฟล์ที่ต้องการคือ ${allowTypeFile.join(' ')}`, 'danger')
        return
      }

      this.$emit('onChange', file)
      this.readExcelFile(file)
    },
    async readExcelFile(file) {
      //   console.log('readExcelFile', file)
      this.progressUpload = 0
      this.uploading = true
      clearTimeout(this.timeOut)

      const reader = new FileReader()

      reader.onload = e => {
        try {
          // get data
          const bytes = new Uint8Array(e.target.result)

          /* read workbook */
          const wb = read(bytes)

          /* grab first sheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]

          /* generate HTML */
          const sheetArr = utils.sheet_to_json(ws)

          this.timeOut = setTimeout(() => {
            this.uploading = false
            this.progressUpload = 0
            this.$emit('uploadSuccess', sheetArr, file)
            this.$refs.fileUploadInput.value = null
          }, 500)
        } catch (error) {
          this.uploading = false
          this.progressUpload = 0
          this.$refs.fileUploadInput.value = null
          // console.log('error', error)
          this.gDisplayToast('แปลงไฟล์ไม่สำเร็จ', 'โปรดติดต่อแอดมิน', 'danger')
        }
      }

      reader.onprogress = e => {
        if (e.lengthComputable) {
          const progress = Math.round((e.loaded / e.total) * 100)
          // console.log('progress', progress)
          this.progressUpload = progress
        }
      }

      reader.onerror = () => {
        // console.log('reader error', reader.error)
        this.$refs.fileUploadInput.value = null
        this.gDisplayToast(`[${reader.error.code}] อ่านไฟล์ไม่สำเร็จ`, 'โปรดติดต่อแอดมิน', 'danger')
      }

      reader.readAsArrayBuffer(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.ct-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
