<template>
  <div>
    <b-card>
      <div class="ct-container">
        <feather-icon
          icon="UploadCloudIcon"
          size="50"
        />
        <br />
        <h4 class="mb-50">คลิกเพื่ออัปโหลดไฟล์</h4>
        <small class="text-muted mb-50">หรือ</small>
        <small>ลากไฟล์มาที่นี้เพื่ออัปโหลด</small>
        <small v-if="accept">ต้องการไฟล์นามสกุล {{ accept }} เท่านั้น</small>
        <input
          id="fileUploadInput"
          ref="fileUploadInput"
          type="file"
          name="fileUploadInput"
          class="file-upload"
          :accept="accept"
          :disabled="uploading"
          @change="onChange"
        />
      </div>
      <b-progress
        v-if="uploading"
        animated
        :value="progressUpload"
        variant="primary"
        class="'progress-bar-primary mt-1"
      />
    </b-card>
    <!-- <pre>{{ JSON.stringify(uploading, null, 2) }}</pre> -->
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progressUpload: 0,
      uploading: false,
      timeOut: null,
    }
  },
  methods: {
    onChange(ev) {
      const file = ev.target.files[0]

      if (!file) return

      if (this.accept) {
        const arrFilename = `${file.name}`.split('.')

        const fileType = `.${arrFilename[arrFilename.length - 1]}`
        const allowTypeFile = this.accept.split(',')

        const validate = allowTypeFile.includes(fileType)

        if (!validate) {
          this.$refs.fileUploadInput.value = null
          // console.log('validate fail')
          this.gDisplayToast('นามสกุลไฟล์ไม่ถูกต้อง', `นามสกุลไฟล์ที่ต้องการคือ ${allowTypeFile.join(' ')}`, 'danger')
          return
        }
      }
      //   console.log('file', file)
      this.$emit('onChange', file)
      this.uploadFile(file)
      //   this.file = file
      //   this.fileList.push(file)
    },
    async uploadFile(file) {
      if (!this.url) {
        this.$refs.fileUploadInput.value = null
        return
      }

      clearTimeout(this.timeOut)
      const formData = new FormData()
      formData.append('file', file)

      const config = {
        onUploadProgress: progressEvent => {
          const percentSuccess = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          this.progressUpload = percentSuccess

          if (percentSuccess === 100) {
            // console.log('upload success')
          }
        },
        useAlert: {
          successEnable: false,
          //   title: 'upload success',
        },
      }
      this.progressUpload = 0
      this.uploading = true
      const res = await this.$http.post(this.url, formData, config).catch(() => null)
      //   console.log('res', res)
      this.timeOut = setTimeout(() => {
        this.uploading = false
        this.$refs.fileUploadInput.value = null
        if (res && res.code === 200) {
          this.$emit('uploadSuccess', res)
        } else {
          this.$emit('uploadFail', res)
        }
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.ct-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
