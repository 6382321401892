import LocalBase from 'localbase'
import Vue from 'vue'

const db = new LocalBase('db')

db.config.debug = false

Vue.prototype.$db = db

export default db
