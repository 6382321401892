<template>
  <div class="mb-2">
    <b-row
      cols="1"
      cols-sm="2"
    >
      <b-col
        v-for="column in formColumns"
        :key="column.field"
      >
        <b-form-group v-if="column.advanceSearch.type === 'select'">
          <label>{{ column.label }}</label>
          <input-select-widget
            v-model="form[column.field].value"
            :loading="form[column.field].loading"
            :get-option-label="column.advanceSearch.getOptionLabel"
            :options="form[column.field].options"
          />
        </b-form-group>
        <b-form-group v-else-if="column.advanceSearch.type === 'number'">
          <label>{{ column.label }}</label>
          <b-form-input
            v-model="form[column.field]"
            type="number"
          />
        </b-form-group>
        <b-form-group v-else-if="column.advanceSearch.type === 'date'">
          <label>{{ column.label }}</label>
          <b-form-input
            v-model="form[column.field]"
            type="text"
          />
        </b-form-group>
        <b-form-group v-else>
          <label>{{ column.label }}</label>
          <b-form-input
            v-model="form[column.field]"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div
      v-if="formColumns.length != 0"
      class="d-flex justify-content-sm-end flex-column flex-sm-row"
    >
      <button-loading-widget
        text="ค้นหา"
        variant="primary"
        class="mb-50 mb-sm-0 mr-0 mr-sm-50"
        :loading="false"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="getSearch"
      />
      <button-loading-widget
        text="ล้างการค้นหา"
        variant="secondary"
        :disabled="false"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="resetSearch"
      />
    </div>

    <!-- <pre>{{ form }}</pre> -->
    <!-- <pre>{{ formColumns }}</pre> -->
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      default() {
        return [
          {
            label: 'name',
            field: 'name',
            advanceSearch: {
              enable: true,
              type: 'string',
            },
          },
          {
            label: 'price',
            field: 'price',
            advanceSearch: {
              enable: true,
              type: 'number',
            },
          },
          {
            label: 'date',
            field: 'date',
            advanceSearch: {
              enable: true,
              type: 'date',
            },
          },
          {
            label: 'color',
            field: 'color',
            selectOptionUrl: '',
            getOptionLabel: option => `${JSON.stringify(option)}`,
            advanceSearch: {
              enable: true,
              type: 'select',
              selectOptionUrl: '',
              defaultOptions: [1, 2, 3, 4],
              getOptionLabel: option => `${JSON.stringify(option)}`,
            },
          },
          {
            label: 'type',
            field: 'type',
            selectOptionUrl: '/api/miscellaneous-data/group/car_data/car_data_type_id',
            getOptionLabel: option => `${JSON.stringify(option)}`,
            advanceSearch: {
              enable: true,
              type: 'select',
              selectOptionUrl: '/api/miscellaneous-data/group/car_data/car_data_type_id',
              getOptionLabel: option => `${JSON.stringify(option)}`,
              getOption: option => [...option],
            },
          },
        ]
      },
    },
  },
  data() {
    return {
      form: {},
      formColumns: [],
    }
  },
  mounted() {
    this.generateForm()
  },
  methods: {
    generateForm() {
      const newForm = {}
      const newFormColumns = []
      const options = []

      this.columns.forEach(el => {
        if (!el.advanceSearch?.enable) return
        // console.log('el', el)
        let formValue = ''
        if (el.advanceSearch.type === 'date') {
          formValue = null
        } else if (el.advanceSearch.type === 'select') {
          formValue = {
            options: [],
            loading: false,
            value: null,
          }
          options.push({
            field: el.field,
            url: el.advanceSearch.selectOptionUrl,
            options: el.advanceSearch.defaultOptions ?? null,
            getOptions: el.advanceSearch.getOptions ?? null,
          })
        }

        newForm[el.field] = formValue
        newFormColumns.push({ ...el })
      })

      this.form = { ...newForm }

      this.formColumns = [...newFormColumns]
      for (let index = 0; index < options.length; index += 1) {
        const element = options[index]

        this.getOptions(element)
      }
    },
    async getOptions(formOption) {
      // console.log('getOptions', formOption)
      if (formOption.options) {
        this.form[formOption.field].options = [...formOption.options]
        return
      }
      if (!formOption.url) return
      this.form[formOption.field].loading = true
      const resp = await this.$http.get(formOption.url).catch(() => null)
      if (resp && resp.code === 200) {
        // console.log('resp', resp)
        if (formOption.getOptions) {
          this.form[formOption.field].options = formOption.getOptions([...resp.data])
        } else {
          this.form[formOption.field].options = [...resp.data]
        }
      }
      this.form[formOption.field].loading = false
    },
    resetSearch(callEmit = true) {
      const newForm = {}
      Object.keys(this.form).forEach(k => {
        if (typeof this.form[k] === 'object') {
          if ('options' in this.form[k]) {
            newForm[k] = { ...this.form[k], value: null }
          } else {
            newForm[k] = null
          }
        } else {
          newForm[k] = ''
        }
      })
      this.form = { ...newForm }
      if (callEmit) {
        this.$emit('resetSearch', {
          columnFilters: {},
          sort: [
            {
              field: '',
              type: '',
            },
          ],
          page: 1,
          perPage: 10,
        })
      }
    },
    getSearch() {
      const serverParams = {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      }

      Object.keys(this.form).forEach(k => {
        const el = this.form[k]
        let currValue = el

        if (typeof el === 'object') {
          currValue = el.value
        }

        if (!currValue) return

        if (typeof currValue === 'object') {
          currValue = currValue.value ?? currValue.id
        }

        serverParams.columnFilters[k] = currValue
      })

      this.$emit('search', { serverParams, rawData: this.form })
    },
  },
}
</script>

<style lang="scss" scoped></style>
