<template>
  <div>
    <b-overlay
      :show="disabled"
      opacity="0.1"
      variant="dark"
    >
      <template v-slot:overlay><p></p> </template>
      <cleave
        v-model="inputVal"
        :name="name"
        class="form-control"
        :class="[error ? 'is-invalid' : null]"
        :raw="true"
        :options="options"
        :placeholder="placeholder"
      />
    </b-overlay>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  components: { Cleave },
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return { numeral: true }
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', Number(val))
      },
    },
  },
  watch: {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
