import Vue from 'vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/prefer-default-export
export const methodMixin = {
  /**
   * แยกข้อมูลออกจาก master data
   * @note เอาไว้แยกข้อมูลออกมาจากข้อมูลหลัก เวลาแก้แล้วจะไม่ไปกระทบกับตับ master data
   * @param {Object} src
   */
  gCp(src) {
    return JSON.parse(JSON.stringify(src))
  },

  gFormatNumberToCurrency(value) {
    const f = new Intl.NumberFormat('th-TH')
    return f.format(value)
  },

  gDisplayToast(title = '', text = '', variant = 'success', icon = 'BellIcon') {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title,
        icon,
        text,
        variant,
      },
    })
  },

  async gCheckConfirm(title = 'แน่ใจใช่หรือไม่ ?', text = 'โปรดตรวจสอบความถูกต้อง') {
    const { isConfirmed } = await Vue.swal({
      title,
      text,
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-outline-secondary ml-50',
      },
      buttonsStyling: false,
    })
    return isConfirmed
  },

  async gCheckConfirmV2(titleText = 'ยืนยัน', bodyText = 'โปรดกดยืนยันอีกครั้ง เพื่อดำเนินการต่อไป') {
    const check = await this.$bvModal.msgBoxConfirm(bodyText, {
      title: titleText,
      centered: true,
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      cancelVariant: 'flat-secondary',
      okTitle: 'ยืนยัน',
      cancelTitle: 'ยกเลิก',
      bodyClass: 'py-2',
      // headerClass: 'modal-confirm-custom',
      // headerTextVariant: 'light',
      // headerBgVariant: 'primary',
    })

    return check
  },

  gOpenPageLoading() {
    Vue.swal({
      html: '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
      showDenyButton: false,
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      background: 'transparent',
    })
  },

  gClosePageLoading() {
    Vue.swal.close()
  },

  gScrollToTop() {
    const rootEle = document.documentElement
    rootEle.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },

  gMockAsync(delay = 1000) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, delay)
    })
  },

  gMockGetOptions(length = 5) {
    return Array.from({ length }).map((v, index) => ({ id: index, value: index + 1 }))
  },
}

Vue.mixin({
  methods: methodMixin,
})
