<template>
  <v-select
    v-model="inputValue"
    :multiple="multiple"
    :loading="loading"
    :options="options"
    :label="label"
    :get-option-label="getOptionLabel"
    :class="[error ? 'error' : null]"
    :placeholder="placeholder"
    :clearable="clearable"
  >
    <template
      slot="spinner"
      slot-scope="props"
    >
      <b-spinner
        v-if="props.loading"
        variant="primary"
        small
      />
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    value: {
      type: [String, Object, Number, Array],
      default() {
        return null
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'label',
    },
    getOptionLabel: {
      type: Function,
      default(option) {
        if (typeof option === 'object') {
          // eslint-disable-next-line no-prototype-builtins
          if (!option.hasOwnProperty(this.label)) {
            return console.warn(
              `[vue-select warn]: Label key "option.${this.label}" does not` +
                ` exist in options object ${JSON.stringify(option)}.\n` +
                'https://vue-select.org/api/props.html#getoptionlabel',
            )
          }
          return option[this.label]
        }
        return option
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        return this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
