<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    :disabled="disabled || loading"
    :variant="variant"
    :block="block"
    :size="size"
    :to="to"
    @click="onClick"
  >
    <b-spinner
      v-if="loading"
      small
      :class="[text ? 'mr-50' : '']"
    />
    <feather-icon
      v-if="!loading && icon"
      :icon="icon"
      :class="[text ? 'mr-50' : '']"
    />
    <span v-if="text">{{ text }}</span>
  </b-button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    to: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {}
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped></style>
