<template>
  <b-card no-body>
    <div
      v-if="newAble"
      class="p-50"
    >
      <div class="d-flex justify-content-sm-end flex-column flex-sm-row">
        <button-loading-widget
          :text="newAbleText"
          variant="primary"
          :loading="isLoading"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          icon="PlusCircleIcon"
          @click="$emit('clickNew', '')"
        />
      </div>
    </div>
    <vue-good-table
      style-class="vgt-table"
      :columns="cColumns"
      :rows="rows"
      :is-loading="isLoading"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <!-- column header -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <div :style="{ width: 'max-content' }">
          {{ props.column.label }}
        </div>
      </template>

      <!-- column filter -->
      <template
        slot="column-filter"
        slot-scope="{ column, updateFilters }"
      >
        <!-- <pre>{{ column }}</pre> -->

        <span v-if="column.field === 'action'"></span>

        <slot
          v-else
          name="column-filter"
          :column="column"
          :updateFilters="updateFilters"
        >
          <b-form-input
            :placeholder="`ค้นหา ${column.label}`"
            size="sm"
            @input="value => updateFilters(column, value)"
          />
        </slot>
      </template>

      <!-- rows -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName1'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Number -->
        <span v-else-if="props.column.type === 'number'">
          {{ gFormatNumberToCurrency(props.row[props.column.field]) }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <!--  click view data -->
            <b-dropdown-item
              v-if="viewAble"
              @click="$emit('clickView', props.row)"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>ดูข้อมูล</span>
            </b-dropdown-item>

            <!-- click edit -->
            <b-dropdown-item
              v-if="editAble"
              @click="$emit('clickEdit', props.row)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>แก้ไขข้อมูล</span>
            </b-dropdown-item>

            <!-- click delete -->
            <b-dropdown-item
              v-if="deleteAble"
              @click="$emit('clickDelete', props.row)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>ลบข้อมูล</span>
            </b-dropdown-item>

            <!-- click select -->
            <b-dropdown-item
              v-if="selectAble"
              @click="$emit('clickSelect', props.row)"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
              <span>{{ selectAbleText }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- loading -->
      <template slot="loadingContent">
        <div class="ct-table-empty">
          <b-spinner variant="primary" />
        </div>
      </template>

      <!-- empty -->
      <template slot="emptystate">
        <div class="ct-table-empty">
          <h5
            v-if="!isLoading"
            class="m-0 text-muted"
          >
            ไม่พบข้อมูล
          </h5>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap px-1 pb-1">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> แสดง 1 ถึง </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50']"
              class="mx-1"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> จากจำนวน {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            label: 'Name1',
            field: 'fullName',
          },
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Date',
            field: 'startDate',
          },
          {
            label: 'Salary',
            field: 'salary',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: '',
            field: 'action',
          },
        ]
      },
    },
    viewAble: {
      type: Boolean,
      default: false,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    },
    newAble: {
      type: Boolean,
      default: false,
    },
    newAbleText: {
      type: String,
      default: 'เพิ่มข้อมูล',
    },
    selectAble: {
      type: Boolean,
      default: false,
    },
    selectAbleText: {
      type: String,
      default: 'เลือก',
    },
  },
  data() {
    return {
      isLoading: false,
      pageLength: 10,
      rows: [],
    }
  },
  computed: {
    cColumns() {
      return this.columns.map(data => {
        if (!data.label || data.label == 'action') {
          data.sortable = false
          return data
        }
        return {
          ...data,
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        }
      })
    },
  },
  created() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      this.isLoading = true
      const resp = await this.$http.get(this.url).catch(() => null)
      // console.log(resp)
      if (resp && resp.code === 200) {
        this.rows = [...resp.data]
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
.ct-table-empty {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vgt-loading {
  top: 25%;
  margin-top: 0px;
}
</style>
