<template>
  <b-card no-body>
    <!-- <div
      class="custom-search d-flex justify-content-end"
      v-if="newAble"
    >
      <div
        class="d-flex align-items-center mb-1"
        v-if="!_loading"
      >
        <b-button
          @click="$emit('clickNew', '')"
          variant="outline-info"
        >
          {{ btnText }}
        </b-button>
      </div>
    </div> -->
    <vue-good-table
      mode="remote"
      style-class="vgt-table"
      :total-rows="totalRecords"
      :is-loading="isLoading"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :rows="rows"
      :columns="_columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName1'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Number -->
        <span v-else-if="props.column.type === 'number'">
          {{ gFormatNumberToCurrency(props.row[props.column.field]) }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none p-0"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <!--  click view data -->
            <b-dropdown-item
              v-if="viewAble"
              @click="$emit('clickView', props.row)"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>ดูข้อมูล</span>
            </b-dropdown-item>

            <!-- click edit -->
            <b-dropdown-item
              v-if="editAble"
              @click="$emit('clickEdit', props.row)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>แก้ไขข้อมูล</span>
            </b-dropdown-item>

            <!-- click delete -->
            <b-dropdown-item
              v-if="deleteAble"
              @click="$emit('clickDelete', props.row)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>ลบข้อมูล</span>
            </b-dropdown-item>

            <!-- click select -->
            <b-dropdown-item
              v-if="selectAble"
              @click="$emit('clickSelect', props.row)"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
              <span>{{ selectAbleText }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- column filter -->
      <template
        slot="column-filter"
        slot-scope="{ column, updateFilters }"
      >
        <!-- <pre>{{ column }}</pre> -->

        <span v-if="column.field === 'action'"></span>

        <slot
          v-else
          name="column-filter"
          :column="column"
          :updateFilters="updateFilters"
        >
          <b-form-input
            :placeholder="`ค้นหา ${column.label}`"
            size="sm"
            @input="value => debounceInput(column, value, updateFilters)"
          />
        </slot>
      </template>

      <template slot="loadingContent">
        <div class="ct-table-empty">
          <b-spinner variant="primary" />
        </div>
      </template>

      <template slot="emptystate">
        <div class="ct-table-empty">
          <h5
            v-if="!isLoading"
            class="m-0 text-muted"
          >
            ไม่พบข้อมูล
          </h5>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap px-1 pb-1">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50']"
              class="mx-1"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'

export default {
  props: {
    items: [],
    title: String,
    pParams: [],
    btnText: {
      type: String,
      default: 'เพิ่มข้อมูล',
    },
    url: String,
    method: {
      type: String,
      default: 'get',
    },
    viewAble: {
      type: Boolean,
      default: false,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    deleteAble: {
      type: Boolean,
      default: false,
    },
    newAble: {
      type: Boolean,
      default: false,
    },
    selectAble: {
      type: Boolean,
      default: false,
    },
    selectAbleText: {
      type: String,
      default: 'เลือก',
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            label: 'Name1',
            field: 'fullName',
          },
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Date',
            field: 'startDate',
          },
          {
            label: 'Salary',
            field: 'salary',
          },
          {
            label: 'Status',
            field: 'status',
          },
          {
            label: 'Action',
            field: 'action',
          },
        ]
      },
    },
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      debounceInput: null,
      pageLoading: false,
      pageLength: 10,
      isLoading: false,
      searchTerm: '',
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    _loading() {
      return this.pageLoading
    },
    // eslint-disable-next-line no-underscore-dangle
    _columns() {
      return this.columns.map(data => {
        if (!data.label || data.label == 'action') {
          data.sortable = false
          return data
        }
        return {
          ...data,
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        }
      })
    },
  },
  created() {
    this.loadItems()
    this.debounceInput = _.debounce((column, value, updateFilters) => {
      updateFilters(column, value)
    }, 500)
  },
  beforeDestroy() {
    this.debounceInput.cancel()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      // console.log(params)
      this.updateParams({
        sort: [
          {
            type: params[0].type === 'none' ? 'asc' : params[0].type,
            field: params[0].field,
          },
        ],
        // sort: [
        //   {
        //     type: params[0].type,
        //     field: this.columns[0].field,
        //   },
        // ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      // console.log({ params })
      this.updateParams(params)
      this.loadItems()
    },
    async reloading() {
      this.loadItems()
    },
    // load items is what brings back the rows from server
    async loadItems() {
      if (!this.url) return
      const params = {
        ...this.serverParams,
        filter: this.searchTerm,
        stock_data_type_id: '734a1d8a-f1db-4665-b06a-5a09ec48348d',
      }
      // console.log('load', params)
      this.isLoading = true
      const res = await this.$http.post(this.url, params, { useAlert: { successEnable: false } }).catch(() => {
        this.isLoading = false
      })
      // console.log('res', res)
      if (res) {
        const { meta, data } = res
        this.totalRecords = meta.total
        this.rows = [...data]
      }
      this.isLoading = false
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
.ct-table-empty {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vgt-loading {
  top: 25%;
  margin-top: 0px;
}
</style>
