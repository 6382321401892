export default [
  {
    path: '/rate-dckk/sale-view',
    name: 'rate-dckk-sale-view',
    component: () => import('@/views/honda-page/rate-dckk/sale-view/SaleViewRatePage.vue'),
    meta: {
      layout: 'full',
      resource: 'ACL',
      action: 'read',
      title: 'DCKK - Rate',
      needMeta: true,
    },
  },
  {
    path: '/rate-dckk/sale-view/show-rate/:id',
    name: 'rate-dckk-sale-view-show-rate',
    component: () => import('@/views/honda-page/rate-dckk/sale-view/SaleViewShowRatePage.vue'),
    meta: {
      layout: 'full',
      resource: 'ACL',
      action: 'read',
      title: 'DCKK - Show Rate',
      needMeta: true,
    },
  },
  {
    path: '/rate-dckk/motorbike-management',
    name: 'rate-dckk-motorbike-management',
    component: () => import('@/views/honda-page/rate-dckk/motorbike-management/MotorbikeManagementPage.vue'),
    meta: {
      title: 'DCKK - Motorbike',
    },
  },
  {
    path: '/rate-dckk/finance-management',
    name: 'rate-dckk-finance-management',
    component: () => import('@/views/honda-page/rate-dckk/finance-management/FinanceManagementPage.vue'),
    meta: {
      title: 'DCKK - Finance',
    },
  },
  {
    path: '/rate-dckk/rate-management',
    name: 'rate-dckk-rate-management',
    component: () => import('@/views/honda-page/rate-dckk/rate-management/RateManagementPage.vue'),
    meta: {
      title: 'DCKK - Rate',
    },
  },
  {
    path: '/rate-dckk/excel',
    name: 'rate-dckk-excel',
    component: () => import('@/views/honda-page/rate-dckk/excel/Excel.vue'),
    meta: {
      title: 'DCKK - Excel',
    },
  },
]
